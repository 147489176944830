// Import only what you need from Firebase SDK
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyAo8dL1qXm1qu6wIs0RORbG_R8_Nmk_kh4",
    authDomain: "deentire-application.firebaseapp.com",
    projectId: "deentire-application",
    storageBucket: "deentire-application.appspot.com",
    messagingSenderId: "86656163406",
    appId: "1:86656163406:web:61e5ae53cadd04b7676d1f",
    measurementId: "G-WKF1SZPF3N"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

// Export storage to use it in other files
export { storage };
