import { Typography, Box, Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { MdOutlineContentCopy } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 400,
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: 24,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            width: '100%',
        },
    },
    shareModalContent: {
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
}));

export default function ShareModal({ openShareModal, handleCloseShareModal, handleCopyLink, shareUrl }) {
    const classes = useStyles();

    return (
        <Modal open={openShareModal} onClose={handleCloseShareModal}>
            <Box className={classes.modalContent}>
                <Box className={classes.shareModalContent}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        Share your project with people
                    </Typography>
                    <Typography style={{ fontSize: '18px', color: '#7D7D7D' }}>
                        It helps you reach your goals
                    </Typography>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '30px',
                            gap: '10px',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* Facebook Share Button */}
                        <FacebookShareButton
                        title='Hello help this guy'
                            url={shareUrl}
                            style={{
                                border: '1px solid lightGrey',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                alignItems: 'center',
                                padding: '5px 20px',
                                borderRadius: '5px',
                                width: '45%',
                            }}
                        >
                            <FacebookIcon size={32} round />
                            <Typography style={{ fontWeight: 'normal' }}>Facebook</Typography>
                        </FacebookShareButton>

                        {/* WhatsApp Share Button */}
                        <WhatsappShareButton
                            url={shareUrl}
                            style={{
                                border: '1px solid lightGrey',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                alignItems: 'center',
                                padding: '5px 20px',
                                borderRadius: '5px',
                                width: '45%',
                            }}
                        >
                            <WhatsappIcon size={32} round />
                            <Typography>WhatsApp</Typography>
                        </WhatsappShareButton>
                    </div>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            border: '1px solid lightGrey',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            marginTop: '15px',
                            cursor: 'pointer',
                        }}
                        onClick={handleCopyLink}
                    >
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            Copy link
                        </Typography>
                        <MdOutlineContentCopy style={{ fontSize: '20px', cursor: 'pointer' }} />
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
