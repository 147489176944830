import React, { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { constants } from "../Helpers/constantsFile";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { logout, setActiveUser } from "../SignupAndLogin/loginSlice";

const useStyles = makeStyles((theme) => ({
    profileContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: "500px",
        width: "100%",
        margin: "30px auto",
    },
    profileImage: {
        width: theme.spacing(25),
        height: theme.spacing(18),
        marginBottom: theme.spacing(2),
    },
    changeImageLink: {
        color: "#02BFC6",
        cursor: "pointer",
        marginBottom: theme.spacing(4),
        textDecoration: "underline",
    },
    formField: {
        marginBottom: theme.spacing(3),
        width: "100%",
    },
    modalContent: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: 400,
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: 24,
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3),
        },
    },
    modalField: {
        marginBottom: theme.spacing(2),
        width: "100%",
    },
}));

const Profile = () => {
    const classes = useStyles();
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const organizer = useSelector((state) => state.login.activeUser);
    const token = useSelector((state) => state.login.token);
    const [name, setName] = useState(organizer?.name || "");
    const [phone, setPhone] = useState(organizer?.phone || "");
    const [username, setUsername] = useState(organizer?.username || "");
    const [disabled, setDisabled] = useState(false);

    const dispatch = useDispatch();

    const handlePasswordModalOpen = () => setIsPasswordModalOpen(true);
    const handlePasswordModalClose = () => {
        setIsPasswordModalOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };

    const handleUpdateClick = async () => {
        setDisabled(true);
        axios.patch(`${constants.baseUrl}/organizers/${organizer?.id}`, {
            name: name,
            uername: username,
            phone: phone
        },
            {
                headers: {
                    "authorization": token
                }
            }).then((res) => {
                setDisabled(false)
                const organizer = res?.data?.data?.organizer
                dispatch(setActiveUser(organizer))
                alert("Successfully updated!")
            }).catch((err) => {
                setDisabled(false)
                alert(err?.response?.data?.message);
            });
    };



    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert("New password and confirmation do not match.");
            return;
        }

        try {
            await axios.post(
                `${constants.baseUrl}/organizers/change-password/${organizer?.id}`,
                {
                    passwordCurrent: currentPassword,
                    password: newPassword,
                    passwordConfirm: confirmPassword,
                },
                {
                    headers: {
                        authorization: token,
                    },
                }
            );
            alert("Password changed successfully!");
            logout()
            handlePasswordModalClose();
        } catch (error) {
            alert(error.response?.data?.message || "Failed to change password.");
        }
    };

    return (
        <Box className={classes.profileContainer} >
            <Avatar
                src="https://firebasestorage.googleapis.com/v0/b/deentire-application.appspot.com/o/0000noteImages%2Forg.webp?alt=media&token=7e49290f-6043-484f-98f3-f600a403beb3"
                alt="Profile Image"
                style={{ border: "2px solid black", marginBottom: "10px" }}
                sx={{ width: 72, height: 72, borderRadius: "60%" }}
            />
            <Typography
                variant="body2"
                className={classes.changeImageLink}
                onClick={() => console.log("Change Image Clicked")}
            >
                Change Image Profile
            </Typography>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    width: "100%",
                    marginTop: "30px",
                }}
            >
                <TextField
                    label="Name"
                    variant="outlined"
                    className={classes.formField}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputLabelProps={{
                        shrink: !!name, // Shrink only if there is content
                    }}
                />
                <TextField
                    label="Username"
                    variant="outlined"
                    className={classes.formField}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputLabelProps={{
                        shrink: !!username, // Shrink only if there is content
                    }}
                />
                <TextField
                    label="Phone"
                    variant="outlined"
                    className={classes.formField}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    InputLabelProps={{
                        shrink: !!phone, // Shrink only if there is content
                    }}
                />

            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "100%",
                    marginTop: "25px",
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        height: "45px",
                        background: disabled ? "grey" : constants.pColor,
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px",
                    }}
                    disabled={disabled}
                    onClick={handleUpdateClick}
                >
                    Update Profile
                </Button>
                <Button
                    variant="outlined"
                    style={{
                        height: "45px",
                        borderColor: constants.pColor,
                        background: "white",
                        color: constants.pColor,
                        fontWeight: "bold",
                        fontSize: "14px",
                    }}
                    onClick={() => {setIsPasswordModalOpen(true)}}
                >
                    Change Password
                </Button>
            </div>
            
            <Modal open={isPasswordModalOpen} onClose={handlePasswordModalClose}>
                <Box className={classes.modalContent} style={{display: "flex", flexDirection: "column",
                    "gap": "10px"
                }}>
                    <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                        Change Password
                    </Typography>
                    <TextField
                        label="Current Password"
                        type="password"
                        variant="outlined"
                        className={classes.modalField}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <TextField
                        label="New Password"
                        type="password"
                        variant="outlined"
                        className={classes.modalField}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        variant="outlined"
                        className={classes.modalField}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        style={{
                            background: constants.pColor,
                            color: "white",
                            fontWeight: "bold",
                            height: "45px",
                            marginTop: "15px",
                        }}
                        onClick={handleChangePassword}
                    >
                        Update Password
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Profile;
