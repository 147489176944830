import React, { useState } from 'react';
import { Typography, Button, Box, TextField, Modal} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { constants } from '../../Helpers/constantsFile';

const useStyles = makeStyles((theme) => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 400,
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: 24,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    modalField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));

export default function DetailsModal({ open, onClose, onPayNowClick, disabled })  {
    const classes = useStyles();
    const [phone, setPhone] = useState()
    const [name, setName] = useState()

    const payNow = () => {
        if (!phone) return alert("Faldan geli number")
        onPayNowClick({phone: phone, name: name})
    }
    return (
        <Modal open={open} onClose={onClose}>
            <Box className={classes.modalContent}>
                <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>Geli Number-kaaga</Typography>
                <div style={{ display: "flex", flexDirection: "column", gap: "12px", marginTop: "15px" }}>
                    <TextField
                        label="Number"
                        type="number"
                        variant="outlined"
                        className={classes.modalField}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                 <TextField
    label="Your name"
    variant="outlined"
    className={classes.modalField}
    value={name}
    onChange={(e) => setName(e.target.value.slice(0, 18))} // Always truncate to 15 characters
/>

                </div>
                <Button
                    disabled = {disabled}
                    variant='contained'
                    style={{
                        fontWeight: "bold", background: constants.pColor, width: "100%", marginTop: "15px",
                        height: "45px"
                    }}
                    onClick={payNow}
                >
                    Pay now
                </Button>
            </Box>
        </Modal>
    );
};