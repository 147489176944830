import React, { useState } from 'react';
import { Typography, Dialog, DialogContent } from '@mui/material';
import {  MdNotificationsActive } from 'react-icons/md';


const NotificationModal = ({ open }) => (
    <Dialog
        open={open}
        PaperProps={{
            style: {
                borderRadius: '12px',
                padding: '20px',
                textAlign: 'center'
            }
        }}
    >
        <DialogContent>
            <MdNotificationsActive style={{ fontSize: '48px', color: '#00A6A6' }} />
            <Typography variant="h6" fontWeight="bold">Fiiri taleefankaaga</Typography>
            <Typography variant="body2" color="textSecondary">taabo hal kidib geli pin-kaaga</Typography>
        </DialogContent>
    </Dialog>
);

export default NotificationModal