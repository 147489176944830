import { Button, Container } from "@mui/material";

export default function CancelPayment() {

    return (
        <Container>
            <h2> Paymet got cancelled!</h2>
            <Button variant="contained" > Try again</Button>
        </Container>
    )
}