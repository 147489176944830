import { Helmet } from 'react-helmet';

const TestHelmet = () => (
    <>
        <Helmet>
            <title>This is about page</title>
        </Helmet>
        <div>Yeah about us</div>
    </>
);

export default TestHelmet;