import axios from "axios";
import { useEffect, useState } from "react";
import { constants } from "../Helpers/constantsFile";

const localOptionsDefault = [
  {
    operator: "zaad",
    shortNumber: "25263",
    isSelected: false,
  },
  {
    operator: "evc",
    shortNumber: "252",
    isSelected: true,
  },
  {
    operator: "sahal",
    shortNumber: "252",
    isSelected: false,
  },
];

export default function Home() {
  const [paymentMethod, setPaymentMethod] = useState("local");
  const [localPaymentOptions, setLocalPaymentOptions] = useState(localOptionsDefault);
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [orderId, setOrderId] = useState("33321232");

  const handlePaymentClickOption = (index) => {
    setLocalPaymentOptions(
      localPaymentOptions.map((option, i) => ({
        ...option,
        isSelected: i === index,
      }))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post("/api/local", { phone, amount });
      console.log(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handlePaymentSuccess = (response) => {
    console.log("Payment Successful:", response);
    alert("Payment was successful!");
    // Perform actions like updating the UI or storing the transaction data
  };

  const handlePaymentError = (error) => {
    console.error("Payment Error:", error);
    alert("Payment failed. Please try again.");
  };

  const handlePaymentCancel = () => {
    console.log("Payment Cancelled");
    alert("Payment was cancelled.");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://ap-gateway.mastercard.com/static/checkout/checkout.min.js";
    script.async = true;
    script.setAttribute("data-complete", "handlePaymentSuccess");
    script.setAttribute("data-error", "handlePaymentError");
    script.setAttribute("data-cancel", "handlePaymentCancel");

    script.onload = () => {
      console.log("Mastercard script loaded");
      window.handlePaymentSuccess = handlePaymentSuccess;
      window.handlePaymentError = handlePaymentError;
      window.handlePaymentCancel = handlePaymentCancel;
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const configureCheckout = (sessionId) => {
    if (!window.Checkout) {
      console.error("Checkout script not loaded");
      return;
    }

    window.Checkout.configure({
      session: { id: sessionId },
      order: { description: "Order Description", id: orderId },
      interaction: {
        operation: "PURCHASE",
        merchant: { name: "SOOCAAWI.COM" },
      },
    });

    window.Checkout.showPaymentPage();
  };

  const handleOnlinePayment = async () => {
    try {
      const response = await axios.post(
        `${constants.baseUrl}/donations/initiate-mastercard-checkout-session`,
        {
          amount: 1,
          returnUrl: "http://soocaawi.com/#/payment-success",
          cancelUrl: "http://localhost:3000/cancel",
          timeoutUrl: "http://localhost:3000/timeout",
        }
      );

      const session = response?.data?.data?.sessionId;
      alert(`Result Indicator: ${response?.data?.data?.resultIndicator}`);
      setSessionId(session);
      configureCheckout(session);
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  return (
    <main className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Payments</h1>

      <div className="mb-4">
        <button
          className={`mr-2 ${paymentMethod === "local" ? "bg-blue-500 text-white" : "bg-gray-300 text-black"} p-2 rounded`}
          onClick={() => setPaymentMethod("local")}
        >
          Local Payment
        </button>
        <button
          className={`mr-2 p-2 rounded ${paymentMethod === "online" ? "bg-blue-500 text-white" : "bg-gray-300 text-black"}`}
          onClick={() => setPaymentMethod("online")}
        >
          Online Payment
        </button>
      </div>

      {paymentMethod === "local" && (
        <div className="max-w-2xl">
          <h2 className="text-xl mb-2">Local Payment Options</h2>
          {localPaymentOptions.map((option, index) => (
            <div
              onClick={() => handlePaymentClickOption(index)}
              className={`w-full bg-gray-50 rounded border-2 ${option.isSelected && "border-blue-500"} p-2 my-2 cursor-pointer`}
              key={option.operator}
            >
              <h2>{option.operator}</h2>
            </div>
          ))}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="mb-2 p-2 border w-full rounded"
              placeholder="Enter your payment number"
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="text"
              className="mb-2 p-2 border w-full rounded"
              placeholder="Enter your amount"
              onChange={(e) => setAmount(e.target.value)}
            />
            <button className="mr-2 p-2 rounded bg-blue-500 text-white">
              {loading ? "Paying..." : "Pay now"}
            </button>
          </form>
        </div>
      )}

      {paymentMethod === "online" && (
        <>
          <h2 className="text-xl mb-2">Online Payment Options</h2>
          <button onClick={handleOnlinePayment}>Pay now</button>
          <div id="embedded-checkout"></div>
        </>
      )}
    </main>
  );
}
