import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './SignupAndLogin/loginSlice';
import projectsReducer from './containers/projectDetails/projectsSlice'; // Ensure this is correct

const store = configureStore({
  reducer: {
    login: loginReducer, // Must match slice name
    projects: projectsReducer, // Ensure the key matches the slice name
  },
});

export default store;
