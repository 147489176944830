import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-content">
                    <div className="footer-info">
                        <h2>SOOCAAWI.COM</h2>
                        <p>SomFundMe is dedicated to empowering individuals, organizations, and communities in Somalia. We provide a platform to raise funds for causes, projects, and dreams, driving positive change within the Somali community and beyond.</p>
                    </div>
                
                    <div className="footer-contact">
                        <h3>Contact Us</h3>
                        <p>Email: <a href="mailto:somfundme@gmail.com">soocaawi@gmail.com</a></p>
                        <p>Phone: <a href="tel:+252616549198">+252 616549198</a></p>
                        <p>Location: Mogadishu, Somalia</p>
                    </div>
                    <div className="footer-mission">
                        <h3>Our Mission</h3>
                        <p>To provide a safe and accessible platform for fundraising, enabling Somalis to support each other and achieve their common goals.</p>
                    </div>
                    <div className="footer-vision">
                        <h3>Our Vision</h3>
                        <p>To be the most trusted and impactful crowdfunding platform for the Somali community, fostering a spirit of collaboration and mutual aid.</p>
                    </div>
                    <div className="footer-social">
                        <h3>Follow Us</h3>
                        <div className="social-icons">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <p>© SomFundMe 2024. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
