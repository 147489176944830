// src/containers/projects/projectsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  isProjectDataFetched: false
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    addProject: (state, action) => {
      state.projects.push(action.payload);
    },
    deleteProject: (state, action) => {
      state.projects = state.projects.filter(project => project._id !== action.payload._id);
    },
    updateProject: (state, action) => {
      const { _id, updatedProject } = action.payload;
      const index = state.projects.findIndex(project => project._id === _id);
      if (index !== -1) {
        state.projects[index] = { ...state.projects[index], ...updatedProject };
      }
    },
    updateProjectDonations: (state, action) => {
        const { _id, newDonations } = action.payload;
        const index = state.projects.findIndex(project => project._id === _id);
        if (index !== -1) {
            state.projects[index].totalDonations += newDonations; // Increment totalDonations
        }
    },
    updateProjectDonors: (state, action) => {
        const { _id, newDonor } = action.payload;
        const index = state.projects.findIndex(project => project._id === _id);
        if (index !== -1) {
            state.projects[index].totalDonors += newDonor; // Increment totalDonations
        }
    },
    
    updateProjectSocketDonations: (state, action) => {
      const { _id, donationChange } = action.payload;
      const index = state.projects.findIndex(project => project._id === _id);
      if (index !== -1) {
        state.projects[index].totalDonations += donationChange;
      }
    },
    setProjectDataFetched: (state, action) => {
      state.isProjectDataFetched = action.payload;
    },
    logoutProjects: (state, action) => {
      return {
        projects: [],
        isProjectDataFetched: false
      }; // Reset state to initial state
    },
  },
});

export const {
  setProjects,
  addProject,
  deleteProject,
  updateProject,
  updateProjectDonations,
  updateProjectSocketDonations,
  setProjectDataFetched,
  logoutProjects,
  updateProjectDonors
} = projectsSlice.actions;

export default projectsSlice.reducer;
