import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, Backdrop, Typography } from "@mui/material";
import { setActiveUser, setIsLogin, setToken } from "./loginSlice";
import CustomButton from "../reusables/CustomButton";
import { constants } from "../Helpers/constantsFile";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        gap: '16px',
        maxWidth: '400px',
        width: '100%',
        padding: '16px',
        margin: 'auto',
        borderRadius: '10px',
        backgroundColor: 'white',
    },
    inputContainer: {
        width: '100%',
    },
    inputField: {
        width: '100%',
        height: '45px',
        padding: '15px',
        fontSize: '16px',
        border: '1.5px solid grey',
        borderRadius: '6px',
        boxSizing: 'border-box',
    },
    errorText: {
        color: 'red',
        fontSize: '14px',
        textAlign: 'center',
    },
    submitButton: {
        width: '100%',
        maxWidth: '290px',
        marginTop: '10px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Login = (redirectPath) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const [showSpinner, setShowSpinner] = useState(false);
    const [stateValues, setStateValues] = useState("");
    const [usernameOrPasswordError, setUsernameOrPasswordError] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [noInternet, setNoInternet] = useState(false);

    const loginArr = [
        { label: "Enter Username", type: "text", name: "userName" },
        { label: "Enter Password", type: "password", name: "password" },
    ];

    const authenticateFun = async (values) => {
      try {
          const response = await axios.post(`${constants.baseUrl}/organizers/authenticate`, {
              username: values.userName,
              password: values.password,
              version: "notify_version"
          });

          console.log(response?.data?.data)
  
          const user = response.data?.data?.organizer;
          const token = `Bearer ${response?.data?.token}`;
  
          // Dispatch actions to set the Redux state
          dispatch(setActiveUser(user));
          dispatch(setToken(token));
          dispatch(setIsLogin(true));
  
          // Store user info and token in localStorage to maintain session
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
  
          setShowSpinner(false);
          console.log(redirectPath.redirectPath)
          navigate(redirectPath.redirectPath); // Redirect to dashboard
      } catch (err) {
          setShowSpinner(false);
          setUsernameOrPasswordError(err.response?.data?.message || "Incorrect username or password");
      }
  };
  

    const formik = useFormik({
        initialValues: {
            userName: "",
            password: "",
        },
        validate: (values) => {
            const errors = {};
            if (!values.userName) errors.userName = "Field is Required";
            if (!values.password) errors.password = "Field is Required";
            return errors;
        },
        onSubmit: async (values) => {
            setShowSpinner(true);
            setStateValues(values);
        },
    });

    useEffect(() => {
        if (stateValues) authenticateFun(stateValues);
    }, [stateValues]);

    // Connection status check
    useEffect(() => {
        const handleConnectionChange = () => {
            setNoInternet(!navigator.onLine);
            if (navigator.onLine) setDisableButton(false);
        };

        window.addEventListener("online", handleConnectionChange);
        window.addEventListener("offline", handleConnectionChange);
        return () => {
            window.removeEventListener("online", handleConnectionChange);
            window.removeEventListener("offline", handleConnectionChange);
        };
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
            {loginArr.map((inputField, index) => (
                <div key={index} className={classes.inputContainer}>
                    <input
                        placeholder={inputField.label}
                        style = {{width: "100%"}}
                        id={inputField.name}
                        name={inputField.name}
                        type={inputField.type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values[inputField.name]}
                        className={classes.inputField}
                    />
                    {formik.touched[inputField.name] && formik.errors[inputField.name] && (
                        <Typography className={classes.errorText}>
                            {formik.errors[inputField.name]}
                        </Typography>
                    )}
                </div>
            ))}

            <CustomButton 
                type="submit" 
                text="Login"
                bgColor={constants.pColor}
                style={{width: "100%"}}
                disabled={disableButton}
                className={classes.submitButton}
            />

            {showSpinner && (
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            {noInternet && !disableButton && (
                <Typography className={classes.errorText}>No internet connection</Typography>
            )}
            {!noInternet && usernameOrPasswordError && (
                <Typography className={classes.errorText}>{usernameOrPasswordError}</Typography>
            )}
            <Typography style = {{fontSize: "16px"}}> don't have an accoount? <span style = {{fontWeight: "bold", color: constants.pColor, 
                cursor: "pointer", }}  onClick={() => navigate('/signUp')}> Sign up</span></Typography>
        </form>
    );
};

export default Login;
