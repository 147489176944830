import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { MdAccountBalance } from 'react-icons/md';
import { constants } from '../Helpers/constantsFile';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2), // Slightly smaller padding on small screens
        },
        maxWidth: '600px',
        margin: '20px auto',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    balanceContainer: {
        backgroundColor: '#C6FDFF',
        width: '100%',
        padding: theme.spacing(3),
        borderRadius: '8px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    balanceText: {
        color: '#333',
    },
    balanceAmount: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#000',
    },
    withdrawButton: {
        background: '#048E93',
        color: '#fff',
        padding: theme.spacing(1, 3),
        borderRadius: '8px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#004D40',
        },
    },
    transactionsTitle: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#333',
        alignSelf: 'flex-start',
        marginBottom: theme.spacing(2),
    },
    transactionItem: {
        padding: theme.spacing(1.5),
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        backgroundColor: '#FAFAFA',
    },
    transactionDescription: {
        fontWeight: 'bold',
        color: '#333',
    },
    transactionAmount: {
        fontWeight: 'bold',
        color: '#000',
    },
    transactionDate: {
        color: '#777',
    },
    transactionStatus: {
        color: constants.pColor,
        fontWeight: 'bold',
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Adjusts the width for mobile view with margin on the sides
        maxWidth: 400, // Limits width on larger screens
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: 24,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3), // Slightly smaller padding on small screens
        },
    },
    
    modalTitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    modalField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    modalWithdrawButton: {
        backgroundColor: '#048E93',
        color: '#fff',
        fontWeight: 'bold',
        padding: theme.spacing(1.5),
        width: '100%',
        '&:hover': {
            backgroundColor: '#004D40',
        },
    },
}));

const transactions = [
    {
        description: 'Lacagta project-ga jaamac',
        amount: '$2,500',
        date: 'Nov 5, 2024',
        status: 'approved',
    },
    {
        description: 'Lacagta project-ga jaamac',
        amount: '$2,500',
        date: 'Nov 5, 2024',
        status: 'approved',
    },
    {
        description: 'Lacagta project-ga jaamac',
        amount: '$2,500',
        date: 'Nov 5, 2024',
        status: 'approved',
    },
    {
        description: 'Lacagta project-ga jaamac',
        amount: '$2,500',
        date: 'Nov 5, 2024',
        status: 'approved',
    },
];

const MyAccount = () => {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [withdrawDescription, setWithdrawDescription] = useState('');
    const token = useSelector(state => state.login.token)
    const organizer = useSelector(state => state.login.activeUser)
    const [myData, setMyData] = useState()
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const fetchDashboard = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${constants.baseUrl}/organizers/get-organizer-dashboard/${organizer?.id}`,
                    {
                        headers: {
                            authorization: token,
                        },
                    }
                );
    
                const sortedWithdrawals = response?.data?.data?.withdrawals?.sort(
                    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                );
    
                setMyData({
                    ...response?.data?.data,
                    withdrawals: sortedWithdrawals,
                });
                console.log(response?.data?.data);
                setLoading(false);
            } catch (err) {
                alert(err.response?.data?.message);
                setLoading(false);
            }
        };
    
        fetchDashboard();
    }, []);
    
    

    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => {
        setIsModalOpen(false);
        setWithdrawAmount('');
        setWithdrawDescription('');
    };

    const handleWithdraw = () => {
        console.log("Withdraw Amount:", withdrawAmount);
        console.log("Withdraw Description:", withdrawDescription);
    
        axios.post(`${constants.baseUrl}/withdrawals`, {
            description: withdrawDescription,
            amount: withdrawAmount,
            organizer: organizer?.id
        }, {
            headers: {
                'authorization': token
            },
        })
        .then((res) => {
            alert("Successfully created withdrawal")
            const newTransaction = res?.data?.data?.createdWithdrawal;
            setMyData((prevData) => ({
                ...prevData,
                withdrawals: [newTransaction, ...(prevData?.withdrawals || [])],
            }));
            handleModalClose();
            console.log(newTransaction);
        })
        .catch((err) => {
            handleModalClose();
            alert(err.response?.data?.message);
            console.log(err);
        });
    };
    

    return (
        <Box className={classes.container}>
            {/* Balance Display */}
            <Box className={classes.balanceContainer}>
                <Box style = {{display: "flex", flexDirection: "column", alignItems: "center", 
                    justifyContent: "center"
                }}>
                    <Typography variant="body2" className={classes.balanceText}>Your balance</Typography>
                    <Typography variant="h4" className={classes.balanceAmount} style={{ fontWeight: "bold" }}>{loading ? "laoding.." : `$${myData?.totalBalance?.toFixed(2)}`}</Typography>
                </Box>
                <Button
                    variant='contained'
                    style={{ background: "#048E93", height: "45px", fontWeight: "bold", marginTop: "20px",
                        width: "80%"
                     }}
                    onClick={handleModalOpen}
                >
                    Withdraw Money
                </Button>
            </Box>
            <AccountBalances accounts = {myData?.accounts} loading = {loading}/>
            {/* Transactions Title */}
            <Typography style={{ fontSize: "19px", fontWeight: "bold", alignSelf: "start" }}>Transactions</Typography>

            {/* Transactions List */}
            {loading && <p> loading transactions...</p>}
            <div style={{ width: "100%", background: "white", display: "flex", flexDirection: "column", gap: "22px", marginTop: "15px" }}>
                {myData?.withdrawals?.map((transaction, index) => (
                    <div key={index} style={{ display: "flex", flexDirection: "row", width: "100%", gap: "20px", alignItems: "center", fontSize: "18px" }}>
                        <div style={{ background: "#F4F2EC", padding: "10px", borderRadius: "4px" }}>
                            <MdAccountBalance style={{ color: '#757575', fontSize: "20px" }} />
                        </div>
                        <div>
                            <Typography style={{ fontSize: "16px" }}>{transaction?.description}</Typography>
                            <Typography>
                                <span style={{ fontWeight: "bold" }}>${transaction?.amount}</span> - {moment(transaction?.created_at).format("YYYY-MM-DD")}
                            </Typography>
                        </div>
                        <Typography
  style={{
    fontWeight: "bold",
    marginLeft: "auto",
    color:
      transaction.status === "pending"
        ? "blue"
        : transaction.status === "processed"
        ? "black"
        : transaction.status === "approved"
        ? constants.pColor
        : transaction.status === "rejected"
        ? "red"
        : "inherit", // Default color
  }}
>
  {transaction.status}
</Typography>

                    </div>
                ))}
            </div>

            {/* Withdraw Modal */}
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Box className={classes.modalContent}>
                    <Typography style = {{fontWeight: "bold", fontSize: "18px"}}>Withdraw Funds</Typography>
                    <div style = {{display: "flex", flexDirection: "column",  gap: "12px", marginTop: "15px"}}> 
                    <TextField
                        label="Amount"
                        type = "number"
                        variant="outlined"
                        className={classes.modalField}
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        className={classes.modalField}
                        value={withdrawDescription}
                        onChange={(e) => setWithdrawDescription(e.target.value)}
                    />
                    </div>
                    <Button
                    variant='contained'
                        style = {{fontWeight:"bold", background: constants.pColor, width: "100%", marginTop: "15px",
                            height: "45px"
                        }}
                        onClick={handleWithdraw}
                    >
                        Withdraw cash
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

function AccountBalances ({accounts, loading}) {


    return (
        <div style = {{width: "100%", display: "flex", flexDirection: "column", marginBottom: "30px" }}>
        <Typography style={{ fontSize: "19px", fontWeight: "bold", alignSelf: "start", color: "black" }}>Account Balances</Typography>

        {/* Transactions List */}
        <div style={{ width: "100%", background: "white", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "15px",
            gap: "10px"
         }}>
            {loading && <p> Loading accounts...</p>}
            {accounts?.map((account, index) => (
                <div key={index} style={{ display: "flex", flexDirection: "row", width: "100%", gap: "20px", alignItems: "center", fontSize: "18px",
                    padding: "10px 20px", borderRadius: "5px", border: "1px solid lightGrey"
                 }}>
                    
                  <Typography style={{ fontSize: "18px" }}>{account?.accountName}</Typography>
                    
                    <Typography style={{ fontWeight: "normal", fontSize: "18px", color: "black", marginLeft: "auto" }}>
                        ${account.balance?.toFixed(2)}
                    </Typography>
                </div>
            ))}
        </div>
        </div>
    )
}

export default MyAccount;
