import React, { useState } from 'react';
import {  Typography, Button, Dialog } from '@mui/material';
import { useSelector } from 'react-redux';
import { access } from 'original-fs';
import { MdOutlinePayment } from 'react-icons/md';


const PaymentType = ({ open, onClose, onPayCard, onPayEvc, project }) => {
    const virtualAccounts = project?.organizer?.virtualAccounts
    // const virtualAccounts = [
    //     {accountName: "Evc - Plus", platformaccount: {key: "Evc"}},
    //     {accountName: "Mastercard",  platformaccount: {key: "Card"}},

    // ]

    console.log(project)
    console.log("Payment type")
    

    return <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
            style: {
                borderRadius: '12px',
                padding: '20px',
                textAlign: 'center',
                maxWidth: "400px"
            }
        }}
    > 
    <Typography style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center", marginBottom: "10px" }}>Select Payment Type!</Typography>
        <div style={{ display: "flex", width: "1005" , flexDirection: "column", gap: "8px", alignItems: "center", padding: "10px 0px" }}>
           
            {/* <Typography style={{ fontSize: "16px" }}>Your donation was successful!</Typography> */}
            {virtualAccounts?.map(account => {
                return  <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: 'white',
                    border: "1px solid lightGrey",
                    boxShadow: "none",
                    fontWeight: 'normal',
                    marginTop: '0px',
                    color: "black",
                    width: "300px",
                    height: '45px',
                    fontSize: '15px',
                    textAlign: "start"
                }}
                onClick =  {() => {
                    if (account?.platformAccount?.accountType == "evcPlus") onPayEvc(account?.id)
                    if (account?.platformAccount?.accountType == "cardPayment") onPayCard(account?.id)
                }}
            >
                <MdOutlinePayment style = {{marginRight: "20px", fontSize: "18px"}} />
                {account?.accountName}
            </Button>
            })}
           
        </div>
    </Dialog>
};

export default PaymentType
