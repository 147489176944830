import { useState, useEffect } from "react";
import { constants } from "../Helpers/constantsFile";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setProjects } from "../containers/projectDetails/projectsSlice";

const useFetch = (url,  name) => {
  const [isLoading, setLoading] = useState(true)
  const token = useSelector(state => state.login.token)

  const dispatch = useDispatch()
      const [data, setData] = useState(null);
      useEffect(() => {
        setLoading(true)
        const fetchData = async() => {
            const response = await axios
            .get(`${constants.baseUrl}/${url}`, {
              headers: {
                'authorization': token
               },
            }).then((res)=>{
                setData(res.data.data[name])
                dispatch(setProjects(res.data.data[name]))
                console.log(res.data.data[name])
                setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              alert(err.response?.data?.message);
              setLoading(false)
            });
        }
       fetchData()
      }, [url]);
      return {data, isLoading };
};
export default useFetch;