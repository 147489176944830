import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { constants } from "../Helpers/constantsFile";
import { Button, Container } from "@mui/material";

export default function PaymentSuccess() {
const navigate = useNavigate()
  useEffect(() => {
    // Log the full URL and components for debugging
    const fullUrl = window.location.href;
    console.log("Full URL:", fullUrl);

    // Extract parameters manually
    const queryParams = new URLSearchParams(fullUrl.substring(fullUrl.indexOf("?") + 1, fullUrl.indexOf("#")));
    const resultIndicator = queryParams.get("resultIndicator");
    const sessionVersion = queryParams.get("sessionVersion");

    // Confirm payment
    const confirmPayment = () => {
      alert("Confirming Payment");
      axios
        .get(
          `${constants.baseUrl}/donations/confirm-mastercard-payment/${resultIndicator}`
        )
        .then((res) => {
          console.log("API Response:", res);
        })
        .catch((err) => {
          console.error("API Error:", err);
        });
    };

    if (resultIndicator && sessionVersion) {
      confirmPayment();
    }
  }, []);

  return (
  <Container style = {{marginTop: "30px"}}>
    <h2> Successfull!</h2>
    <p> You have successfully donated. Thank you</p>
    <Button variant="contained" style = {{background: constants.pColor}}
    onClick={()=> navigate('/')}> Go TO projects</Button>
    </Container>
  );
}
