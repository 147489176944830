import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { MdCloudUpload } from 'react-icons/md';
import { storage } from '../firebaseConfig';  // Import Firebase storage
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions
import axios from "axios";
import { constants } from "../Helpers/constantsFile";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: theme.spacing(4),
        maxWidth: '500px',
        margin: '20px auto',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    subHeader: {
        color: '#777',
        marginBottom: theme.spacing(3),
    },
    field: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    uploadButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(1.5),
        backgroundColor: '#E0E0E0',
        borderRadius: '8px',
        cursor: 'pointer',
        color: '#333',
        fontWeight: 'bold',
    },
    uploadContainer: {
        position: 'relative',
        width: '100%',
        height: '150px',
        border: '2px dashed #E0E0E0',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: '#FAFAFA',
        marginBottom: theme.spacing(2),
        '&:hover': {
            borderColor: constants.pColor,
        },
    },
    uploadText: {
        display: 'flex',
        alignItems: 'center',
        color: '#555',
        fontSize: '16px',
    },
    hiddenInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    previewImage: {
        width: '100%',
        height: '150px',
        objectFit: 'cover',
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
    },
}));

const CreateProject = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const isLogin = useSelector((state) => state.login.isLogin);
    const token = useSelector((state) => state.login.token);
    const organizer = useSelector((state) => state.login.activeUser);
    const [imageUrl, setImageUrl] = useState("");
    const [disabled, setDisabled] = useState(true)


  // Function to upload image and get the download URL
  const uploadImageToFirebase = async (imageFile) => {
    try {
      // Create a reference for the file in Firebase Storage
      const storageRef = ref(storage, `soocaawi/${imageFile.name}`);

      // Upload the file to the specified reference
      const uploadTaskSnapshot = await uploadBytes(storageRef, imageFile);

      // Get the download URL after the upload is complete
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

    //   alert("Image uploaded successfully!");
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleImageUpload = async (imageFile) => {
    try {
      const imageUrl = await uploadImageToFirebase(imageFile);
      setImageUrl(imageUrl);
      setDisabled(false)
      console.log('Image URL:', imageUrl);
    } catch (error) {
      console.error('Failed to upload image:', error);
    }
  };

  // Call this function when user selects an image
  const onSelectImage = (event) => {
    const imageFile = event.target.files[0];  // Get the image file from file input
    if (imageFile) {
      handleImageUpload(imageFile);
    }
  };

  const updateUserFun = () => {
        //     axios
        //   .patch(`${constants.baseUrl}/projects/${id}`, {logoUrl: imageUrl}, {
        //     headers: {
        //       authorization: token,
        //     },
        //   })
        //   .then((res) => {
        //     alert("Successfully Updated");
        //     handleClose();
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //     alert(err.response?.data?.message);
        //   });
  };

    // State variables for form inputs
    const [title, setTitle] = useState('');
    const [goal, setGoal] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null); // For storing the uploaded image

    useEffect(() => {
        if (!isLogin) {
            navigate('/signIn', { state: { from: location.pathname } });
        }
    }, [isLogin, navigate, location]);

    if (!isLogin) {
        return null; // Prevents the component from rendering if the user is not logged in
    }

    const handleCreateProject = () => {
        if (!title || !goal || !description) {
            alert('Please fill out all fields');
            return;
        }
        if (!imageUrl) return alert("Please upload an image!")

        axios
            .post(
                `${constants.baseUrl}/projects`,
                {
                    title,
                    description,
                    previewImage: imageUrl,
                    organizer: organizer?.id, // Replace with dynamic organizer ID if available
                    targetDonations: parseFloat(goal),
                },
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                alert('Project created successfully');
                navigate('/dashboard'); // Redirect to dashboard or any other page
            })
            .catch((err) => {
                console.error(err);
                alert(err.response?.data?.message || 'An error occurred');
            });
    };

    return (
        <Box className={classes.container}>
            <Typography style={{ fontWeight: 'bold', fontSize: '24px', lineHeight: '1.2', fontFamily: 'Ubuntu, sans-serif' }}>
                Let's Begin Your Fundraising Journey
            </Typography>
            <Typography className={classes.subHeader} style={{ fontSize: '18px', marginTop: '8px' }}>
                Follow the step-by-step guide
            </Typography>

            <Typography style={{ fontSize: '18px', marginTop: '40px', fontWeight: 'bold' }}>
                Let’s create your project now!
            </Typography>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', marginTop: '20px' }}>
                <TextField
                    label="Enter Project Title"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={classes.field}
                />
                <TextField
                    label="Enter Project Goal"
                    variant="outlined"
                    type="number"
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                    className={classes.field}
                />
                     <Box className={classes.uploadContainer}>
                {imageUrl ? (
                    <img src={imageUrl} alt="Preview" className={classes.previewImage} />
                ) : (
                    <Typography className={classes.uploadText}>
                        <MdCloudUpload style={{ marginRight: '10px', fontSize: '18px', color: constants.pColor }} />
                        Upload Image
                    </Typography>
                )}
                <input
                    type="file"
                    className={classes.hiddenInput}
                    onChange={onSelectImage}
                />
            </Box>
                <TextField
                    label="Enter Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={classes.field}
                />
            </div>
            <Button
                variant="contained"
                style={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    background: constants.pColor,
                    height: '45px',
                    marginTop: '15px',
                }}
                onClick={handleCreateProject}
            >
                Create Project
            </Button>
        </Box>
    );
};

export default CreateProject;
