import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { setActiveUser, setIsLogin, setSocketId, setToken } from './SignupAndLogin/loginSlice';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './Pages/HomePage';
import ProjectDetails from './Pages/ProjectDetails';
import MyAccount from './Pages/MyAccount';
import Profile from './Pages/Profile';
import DashboardPage from './Pages/DashboardPage';
import SignupAndLogin from './SignupAndLogin/SignupAndLogin';
import ProtectedRoute from './ProtectedRoute';
import DonatePage from './Pages/DonatePage';
import Signup from './SignupAndLogin/Signup';
import CreateProject from './Pages/CreateProject';
import io from 'socket.io-client';
import { updateProjectDonations, updateProjectDonors } from './containers/projectDetails/projectsSlice';
import PaymentPage from './Pages/PaymentPage';
import TestHelmet from './Pages/TestHelmet';
import {  useNavigate } from 'react-router-dom';
import PaymentSuccess from './Pages/PaymentSuccess';
import CancelPayment from './Pages/CancelPayment';


const App = () => {
    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();
    const mySocketId = useSelector(state => state.login.mySocketId);
    const mySocketIdRef = useRef(null); // Ref to hold the current mySocketId value
    // const navigate = useNavigate();

    const toggleSearch = () => {
        setIsSearching(prev => !prev);
    };

    const resetSearch = () => {
        setIsSearching(false);
    };

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        const storedToken = localStorage.getItem("token");

        if (storedUser && storedToken) {
            dispatch(setActiveUser(JSON.parse(storedUser)));
            dispatch(setToken(storedToken));
            dispatch(setIsLogin(true));
        }
    }, [dispatch]);

    useEffect(() => {
        // Update the ref whenever mySocketId changes
        mySocketIdRef.current = mySocketId;
    }, [mySocketId]);

    useEffect(() => {
        const socket = io.connect('https://soocaawi-api.onrender.com');

        socket.on('connect', () => {
            console.log('Connected to server');
            dispatch(setSocketId(socket.id));
        });

        socket.on('transactionEvent', (data) => {
            console.log("Event triggered")
            console.log(mySocketIdRef.current); // Access the current value of mySocketId from the ref
            // if (mySocketIdRef.current === data?.socketId) return;
            dispatch(updateProjectDonations({ _id: data?.project?.id, newDonations: data?.transaction?.donation }));
            dispatch(updateProjectDonors({ _id: data?.project?.id, newDonor: 1 }));
            // navigate(-1);

        });

        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch]);

    return (
        <Router>
            <Header onSearchToggle={toggleSearch} onLogoClick={resetSearch} />
            <Routes>
                <Route path="/" element={<HomePage isSearching={isSearching} />} />
                <Route path="/project/:id" element={<ProjectDetails />} />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <DashboardPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/my-account"
                    element={
                        <ProtectedRoute>
                            <MyAccount />
                        </ProtectedRoute>
                    }
                />
                <Route path="/donate" element={<DonatePage />} />
                <Route path="/about" element={<TestHelmet />} />
                <Route path="/create" element={<CreateProject />} />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    }
                />
                <Route path="/pay" element={<PaymentPage />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/payment-cancel" element={<CancelPayment />} />
                <Route path="/signIn" element={<SignupAndLogin />} />
                <Route path="/signUp" element={<Signup />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
