import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, TextField, Slider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './DonatePage.module.css';
import DetailsModal from '../containers/donations/DetailsModal';
import NotificationModal from '../containers/donations/NotificationModal';
import SuccessModal from '../containers/donations/SuccessModal';
import axios from 'axios';
import { constants } from '../Helpers/constantsFile';
import ErrorModal from '../containers/donations/ErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectDonations, updateProjectDonors } from '../containers/projectDetails/projectsSlice';
import PaymentType from '../containers/donations/PaymentType';
import { setResultIndicator } from '../SignupAndLogin/loginSlice';


const DonatePage = () => {
    const { state } = useLocation();
    const { project } = state || {};
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState()

    const [donationAmount, setDonationAmount] = useState('');
    const [tipPercentage, setTipPercentage] = useState(17);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorMOdal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [openPaymentType, setOpenPaymentType] = useState(false)
    const [virtaulAccount, setVirtualAccount] = useState()
    const mySocketId = useSelector(state => state.login.mySocketId)
    const [sessionId, setSessionId] = useState()
    const [orderId, setOrderId] = useState("33321232");

    
    const handlePaymentSuccess = (response) => {
        console.log("Payment Successful:", response);
        alert("Payment was successful!");
        // Perform actions like updating the UI or storing the transaction data
      };
    
      const handlePaymentError = (error) => {
        console.error("Payment Error:", error);
        alert("Payment failed. Please try again.");
      };
    
      const handlePaymentCancel = () => {
        console.log("Payment Cancelled");
        alert("Payment was cancelled.");
      };
    
      useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://test-gateway.mastercard.com/static/checkout/checkout.min.js";
        script.async = true;
        script.setAttribute("data-complete", "handlePaymentSuccess");
        script.setAttribute("data-error", "handlePaymentError");
        script.setAttribute("data-cancel", "handlePaymentCancel");
    
        script.onload = () => {
          console.log("Mastercard script loaded");
          window.handlePaymentSuccess = handlePaymentSuccess;
          window.handlePaymentError = handlePaymentError;
          window.handlePaymentCancel = handlePaymentCancel;
        };
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    const dispatch = useDispatch()

    const presetAmounts = [5, 10, 20, 50, 100, 1000, 1500, 2000];

    const handlePresetAmountClick = (amount) => {
        setDonationAmount(amount);
    };

    const handleDonationInputChange = (e) => {
        const value = e.target.value ? parseFloat(e.target.value) : '';
        setDonationAmount(value);
    };

    const handleTipChange = (event, newValue) => {
        console.log(newValue)
        setTipPercentage(newValue);
    };

    const calculateTip = () => (donationAmount * tipPercentage) / 100 || 0;
    const calculateTotal = () => parseFloat(donationAmount || 0) + calculateTip();

    const handlePhonePayment = (phoneNumber) => {
        const isMobile = /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    
        if (!isMobile) {
            alert(`Please use a mobile device to make payments with this number: ${phoneNumber}`);
        } else {
            window.open(`tel:${phoneNumber}`);
        }
    };
    

    const processEvcPayment = (data) => {
        console.log(tipPercentage)
        axios.post(`${constants.baseUrl}/donations/initiate-evcplus-donation-order`, {
            phone: data.phone,
            amount: parseFloat(donationAmount || 0) + calculateTip(),
            // amount: 0.02,
            tip: calculateTip(),
            // tip: 0.01,
            tipPercentage: tipPercentage,
            projectId: project?.id,
            virtualAccountId: virtaulAccount,
            donor: data.name,
            socketId: mySocketId
        },
        ).then((res) => {
            setOpenDetailsModal(false)
        navigate(-1);

            let totalAmount =  parseFloat(donationAmount || 0) + calculateTip()
            console.log(totalAmount);
            
            // Convert the totalAmount to a string, split it into integer and decimal parts
            let [integerPart, decimalPart] = totalAmount.toFixed(2).split('.'); // Ensure two decimal places
            let phoneNumber = `*789*608742*${integerPart}*${decimalPart}#`;
            
            console.log(phoneNumber);
            handlePhonePayment(phoneNumber);
            
            // setOpenSuccessModal(true);
            // const createdDonation = res?.data?.data?.createdDonation
            // setOpenDetailsModal(false);
            // setDisabled(false)
            // console.log(res?.data)
            // dispatch(updateProjectDonations({ _id: project?.id, newDonations: createdDonation?.donation }));
            // dispatch(updateProjectDonors({ _id: project?.id, newDonor: 1 }));
        }).catch((err) => {
            setOpenErrorMOdal(true)
            console.log(err)
            setError(err?.response?.data?.message);
            setOpenDetailsModal(false);
            setOpenNotificationModal(false);
            setDisabled(false)
        });
    }

    const configureCheckout = (sessionId) => {
        if (!window.Checkout) {
          console.error("Checkout script not loaded");
          return;
        }
    
        window.Checkout.configure({
          session: { id: sessionId },
          order: { description: "Order Description", id: orderId },
          interaction: {
            operation: "PURCHASE",
            merchant: { name: "SOOCAAWI.COM" },
          },
          
        });
    
        window.Checkout.showPaymentPage();
      };

    const handleOnlinePayment = async (id) => {
        try {
          const response = await axios.post(
            `${constants.baseUrl}/donations/initiate-mastercard-checkout-session`,
            {
                phone: '616549198',
                amount: parseFloat(donationAmount || 0) + calculateTip(),
                // amount: 0.02,
                tip: calculateTip(),
                // tip: 0.01,
                tipPercentage: tipPercentage,
                projectId: project?.id,
                virtualAccountId: id,
                donor: "Mastercard",
                socketId: mySocketId,
                returnUrl: "http://soocaawi.com/#/payment-success",
                cancelUrl: `http://soocaawi.com/#/project/${project?.id}`,
                timeoutUrl: "http://soocaawi.com/timeout",
            }
          );
    
          const session = response?.data?.data?.sessionId;
          const resultIndicator = response?.data?.data?.resultIndicator
          setSessionId(session);
          configureCheckout(session);
        //   dispatch(setSessionId(session))
        //   dispatch(setResultIndicator(resultIndicator))
        } catch (error) {
          console.error("Error initiating payment:", error);
        }
      };

    const handleDonateNowClick = () => {
        if (!donationAmount) return alert("fadlan dooro xaddiga")
        // setOpenDetailsModal(true);
        setOpenPaymentType(true)
    };

    const handlePayEvcNowClick = (data) => {
        setDisabled(true)
        // setOpenNotificationModal(true);
        processEvcPayment(data)
    };

    const handleSuccessModalClose = () => {
        setOpenSuccessModal(false);
        navigate(-1);
    };

    const handleErroMOdalClose = () => {
        setOpenErrorMOdal(false);
        navigate(-1);
    };
    const handleClosePaymentType = () => {
        setOpenPaymentType(false);
    };

    return (
        <Container className={styles.mainContainer}>
            <Box className={styles.innerBox}>
                <Typography variant="h6" gutterBottom>
                   Waxaa caawineysaa <strong>{project?.title}</strong>
                </Typography>
                <Typography variant="body1" color="textSecondary" style={{ marginBottom: '20px' }}>
                    your donations are secure and protected
                </Typography>

                <Box marginTop={3}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '17px' }} gutterBottom>
                        Halkaan ka dooro deeqda
                    </Typography>
                    <Box className={styles.presetAmountContainer} style={{ marginTop: "20px" }}>
                        {presetAmounts.map((amount) => (
                            <Button
                                key={amount}
                                style={{
                                    background: donationAmount === amount ? "#00A6A6" : "white",
                                    color: donationAmount == amount ? "white" : "black",
                                    borderColor: donationAmount == amount ? "NONE" : "grey",
                                    fontWeight: "bold"
                                }}
                                variant={donationAmount === amount ? "contained" : "outlined"}
                                onClick={() => handlePresetAmountClick(amount)}
                                className={styles.presetAmountButton}
                            >
                                ${amount}
                            </Button>
                        ))}
                    </Box>
                    <TextField
                        type="text"
                        variant="outlined"
                        placeholder='cadadka'
                        fullWidth
                        value={donationAmount}
                        onChange={handleDonationInputChange}
                        inputMode="numeric"
                        InputProps={{
                            startAdornment: (
                                <Typography style={{
                                    fontSize: "20px", fontWeight: "bold", marginRight: "8px",
                                    color: "grey"
                                }}>$</Typography>
                            ),
                            endAdornment: (
                                <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>.00</Typography>
                            ),
                            style: { fontSize: "20px", fontWeight: "bold", textAlign: "center" },
                        }}
                        style={{ marginTop: '15px' }}
                    />
                </Box>

                <Box marginTop={4} textAlign="left">
                    <Typography variant="h7" style={{ fontWeight: 'bold', fontSize: '17px' }} gutterBottom>
                        Help soocaawi services
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontSize: '13px', marginTop: "8px" }}>
                        Waxey shirkadda usahleysaa iney sii waddo howsha ey uheyso bulshada.
                    </Typography>
                    <Slider
                        disabled={donationAmount === ''}
                        value={tipPercentage}
                        onChange={handleTipChange}
                        step={1}
                        min={5}
                        max={30}
                        valueLabelDisplay="auto"
                        aria-labelledby="tip-slider"
                        style={{ margin: '10px 0', color: "lightGrey", height: "4px" }}
                    />
                    <Typography variant="caption" display="block" textAlign="center">
                        {tipPercentage}% tip
                    </Typography>
                </Box>

                <Box marginTop={4} display="flex" flexDirection="column" style={{ borderTop: '1px solid #e0e0e0', paddingTop: '15px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="subtitle1">Your donation</Typography>
                        <Typography variant="body2">${donationAmount ? parseFloat(donationAmount).toFixed(2) : '0.00'}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="subtitle1">Soocaawi service</Typography>
                        <Typography variant="body2">${calculateTip().toFixed(2)}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="subtitle1" fontWeight="bold">Total amount</Typography>
                        <Typography variant="subtitle1" fontWeight="bold">${calculateTotal().toFixed(2)}</Typography>
                    </Box>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleDonateNowClick}
                    style={{
                        backgroundColor: '#00A6A6',
                        fontWeight: 'bold',
                        marginTop: '20px',
                        height: '50px',
                        fontSize: '16px'
                    }}
                >
                    DONATE NOW
                </Button>

                <DetailsModal
                    disabled={disabled}
                    open={openDetailsModal}
                    onClose={() => setOpenDetailsModal(false)}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    name={name}
                    setName={setName}
                    onPayNowClick={handlePayEvcNowClick}
                />

                <NotificationModal open={openNotificationModal} />

                <SuccessModal open={openSuccessModal} onClose={handleSuccessModalClose} />
                <ErrorModal open={openErrorModal} onClose={handleErroMOdalClose} error={error} />
                <PaymentType open={openPaymentType} onClose={handleClosePaymentType} 
               project = {project}
                onPayEvc = {(id)=> { 
                    setVirtualAccount(id)
                    handleClosePaymentType()
                    setOpenDetailsModal(true)}} 
                onPayCard = {(id)=> { 
                    setVirtualAccount(id)
                    handleOnlinePayment(id)
                    handleClosePaymentType()
                }} 
                />

            </Box>
            <div id="embedded-checkout"></div>
        </Container>
    );
};

export default DonatePage;
