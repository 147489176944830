export const constants = {
    baseUrl: 'https://soocaawi-api.onrender.com/api/v1',
    // baseUrl: 'http://192.168.199.172/api/v1',
    // baseUrl: 'https://baroobo-booktire-api.onrender.com/api/client/v1',
    baseAdminUrl: 'https://booktire-api.onrender.com/api/admin/v1',
    moneySign: "$",
    pColor: "#00A6A6",
    sColor: "#E90139", 
    pTextColor: "#CCBED6",
    subColor: "#979797",
    backdropColor: "white",
    activeTabColor: "#9300FF",
    sideBarColor: "#6A03B6",
    colorSubText: "#EAD8F8",
    businnesColor: "#54068D",
    businessSubText: "#975EC0"
}
