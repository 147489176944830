import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isLogin = useSelector((state) => state.login.isLogin);

    return isLogin ? children : <Navigate to="/signIn" />;
};

export default ProtectedRoute;
