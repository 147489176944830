import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem, Avatar, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Header.module.css';
import { MdMenu } from "react-icons/md";
import { SearchOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { logout } from '../SignupAndLogin/loginSlice';
import soocaawiLogo from "../assets/images/soocaawiHeader.png"

const useStyles = makeStyles((theme) => ({
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '90px',
        gap: "15px",
        padding: '2px 15px',
        cursor: 'pointer',
        border: '1px solid grey',
    },
    username: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#000',
    },
    avatar: {
        width: '30px',
        height: '30px',
        backgroundColor: '#000',
        color: '#FFF',
        fontSize: '16px',
    },
    menu: {
        marginTop: theme.spacing(1),
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    menuItem: {
        fontSize: '16px',
        padding: theme.spacing(1.5, 3),
    },
}));

const Header = ({ onSearchToggle, onLogoClick }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const isLogin = useSelector((state) => state.login.isLogin);
    const activeUser = useSelector((state) => state.login.activeUser);
    const isMobile = useMediaQuery("(max-width:600px)");
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const getButtonStyle = (path) => ({
        color: location.pathname === path ? "#02BFC6" : "inherit",
        fontWeight: location.pathname === path ? "bold" : "normal",
    });

    return (
        <AppBar position="sticky" className={styles.header} style={{ background: "white", color: "black" }}>
            <Toolbar className={styles.toolbarContainer}>
                <div className={styles.toolbar}>

                    {/* Left Section with Search Icon in Desktop, Avatar in Mobile */}
                    <div className={styles.leftSection}>
                        {isMobile && isLogin ? (
                            <div
                                style={{
                                    display: "flex", flexDirection: "row", alignItems: "center",
                                    gap: "8px", cursor: "pointer", justifyContent: "center"
                                }}
                                onClick={handleProfileMenuOpen}
                            >
                                <Avatar sx={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: '60%',
                                    background: "black"
                                }}>
                                    <span style={{ fontSize: "12px" }}> {activeUser.name ? activeUser.name[0].toUpperCase() : 'U'} </span>
                                </Avatar>
                                <Typography style={{ fontSize: "13px" }}>
                                    {activeUser.name
                                        ? `${activeUser.name.substring(0, 16)}${activeUser.name.length > 16 ? '...' : ''}`
                                        : 'U'}
                                </Typography>

                            </div>
                        ) : (
                            <IconButton color="inherit" onClick={onSearchToggle}>
                                <SearchOutlined />
                            </IconButton>
                        )}
                    </div>

                    {/* Centered Logo */}
                    {/* <Typography variant="h6" className={styles.logo} onClick={() => { onLogoClick(); navigate('/'); }}>
                        SomFundMe
                    </Typography> */}
                    <img src = {soocaawiLogo} style = {{width: "130px", height: "60px", cursor: "pointer"}} 
                    onClick={() => {
                        navigate('/')
                        onLogoClick()
                    }}
                    className={styles.logo}/>

                    {/* Right Section with Menu and Buttons */}
                    <div className={styles.rightSection}>
                        {/* Desktop Navigation */}
                        <div className={styles.desktopNav}>
                            <Button
                                style={getButtonStyle('/')}
                                onClick={() => navigate('/')}
                            >
                                Home
                            </Button>
                            {isLogin ? (
                                <>
                                    <Button
                                        style={getButtonStyle('/dashboard')}
                                        onClick={() => navigate('/dashboard')}
                                    >
                                        Dashboard
                                    </Button>
                                    <Button
                                        style={getButtonStyle('/my-account')}
                                        onClick={() => navigate('/my-account')}
                                    >
                                        My Account
                                    </Button>
                                    <Box
                                        className={classes.avatarContainer}
                                        onClick={handleProfileMenuOpen}
                                    >

                                        <Avatar className={classes.avatar} sx={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: '60%',
                                            background: "black"
                                        }}>
                                            <span style={{ fontSize: "12px" }}> {activeUser.name ? activeUser.name[0].toUpperCase() : 'U'} </span>

                                        </Avatar>
                                        <Typography className={classes.username}>
                                            {activeUser.name
                                                ? `${activeUser.name.substring(0, 16)}${activeUser.name.length > 16 ? '...' : ''}`
                                                : 'U'}
                                        </Typography>
                                    </Box>
                                    <Menu
                                        anchorEl={profileAnchorEl}
                                        open={Boolean(profileAnchorEl)}
                                        onClose={handleProfileMenuClose}
                                        classes={{ paper: classes.menu }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                navigate('/profile');
                                                handleProfileMenuClose();
                                            }}
                                            className={classes.menuItem}
                                        >
                                            Profile
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                navigate('/create');
                                                handleProfileMenuClose();
                                            }}
                                            className={classes.menuItem}
                                        >
                                            Create Project
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleProfileMenuClose();
                                                dispatch(logout())
                                            }}
                                            className={classes.menuItem}
                                        >
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <Button
                                        style={getButtonStyle('/signIn')}
                                        onClick={() => navigate('/signIn')}
                                    >
                                        Sign In
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        onClick={() => navigate('/create')}
                                        style={{
                                            color: isScrolled ? "white" : "inherit",
                                            borderColor: isScrolled ? "#02BFC6" : "inherit",
                                            background: isScrolled ? "#02BFC6" : "inherit",
                                        }}
                                    >
                                        Create Project
                                    </Button>
                                </>
                            )}
                        </div>

                        {/* Mobile Navigation: Menu in Right Corner */}
                        <div className={styles.mobileNav}>
                            {!isMobile && (
                                <IconButton color="inherit" onClick={onSearchToggle}>
                                    <SearchOutlined />
                                </IconButton>
                            )}
                            <IconButton color="inherit" onClick={handleMenuOpen}>
                                <MdMenu />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                classes={{ paper: styles.mobileMenu }}
                            >
                                <MenuItem onClick={() => { navigate('/'); handleMenuClose(); }}>Home</MenuItem>
                                {isLogin ? (
                                    <>
                                        <MenuItem onClick={() => { navigate('/dashboard'); handleMenuClose(); }}>Dashboard</MenuItem>
                                        <MenuItem onClick={() => { navigate('/my-account'); handleMenuClose(); }}>My Account</MenuItem>
                                        {/* <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>Profile</MenuItem> */}
                                    </>
                                ) : (
                                    <MenuItem onClick={() => { navigate('/signIn'); handleMenuClose(); }}>Sign In</MenuItem>
                                )}
                                {!isLogin && (
                                    <MenuItem onClick={() => { navigate('/create'); handleMenuClose(); }}>Create Project</MenuItem>
                                )}
                            </Menu>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
