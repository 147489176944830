import React, { useEffect, useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../funcrions/DataFetchers';
import styles from './Dashboard.module.css';
import { RiCoinsLine } from "react-icons/ri";
import ShareModal from '../containers/modals/ShareModal';

const DashboardPage = () => {
    const navigate = useNavigate();
    const organizer = useSelector(state => state.login.activeUser)
    const { isLoading, data: projects } = useFetch(`projects/organizer-projects/${organizer?.id}`, 'projects');

    // Dashboard stats

    const [statusCards, setStatusCards] = useState([]);

    useEffect(() => {
        if (projects) {
            const totalRaised = projects.reduce((sum, project) => sum + project.totalDonations, 0);
            const projectCount = projects.length;

            setStatusCards([
                { title: "Amount Raised", value: totalRaised.toLocaleString(), isMoney: true },
                { title: "Project Count", value: projectCount, isMoney: false }
            ]);
        }
    }, [projects]);

    const projectClickHandler = (project) => {
        navigate(`/project/${project.id}`, { state: { project } });
    };

    return (
        <div style={{ background: 'white', width: '100%', marginTop: "5px" }}>
            <Container>
                <h2 className={styles.projectTitle} style={{ marginTop: '25px' }}>
                    Dashboard
                </h2>
            </Container>

            <div style={{ width: "100%", margin: "20px auto", padding: "0px 12px" }}>
                <div className={styles.statsDivCards} style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", width: "100%", gap: "20px" }}>
                    {statusCards.map((status) => (
                        <StatusCard key={status.title} data={status} />
                    ))}
                </div>
            </div>

            <Container>
                <h2 className={styles.projectTitle} style={{ marginTop: '35px', color: "grey", fontSize: "20px" }}>
                    Latest Projects
                </h2>
            </Container>

            <Container>
                {isLoading && <p> projects are loading...</p> }
                <div className={styles.projectGrid}>
                    {projects?.map((project) => (
                        <ProjectCard key={project.id} project={project} onProjectClick={projectClickHandler} />
                    ))}
                </div>
            </Container>
        </div>
    );
};

const ProjectCard = ({ project, onProjectClick }) => {
  const [openShareModal, setOpenShareModal] = useState(false)

  const handleCloseShareModal = () => {
    setOpenShareModal(false)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`window.location.href`);
    alert('Link copied to clipboard!');
};

    return (
        <div className={styles.projectCard}>
            <img src={project.previewImage} alt={project.title} className={styles.projectImage} />
            <div className={styles.projectContent}>
                <p className={styles.donationsText}>{project.totalDonors} donations</p>
                <p className={styles.projectName}>{project.title}</p>
                <div className={styles.buttonGroup}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={styles.projectButton}
                        onClick={() => onProjectClick(project)}
                        style={{ borderColor: "#00A6A6", color: "#00A6A6" }}
                    >
                        Stats
                    </Button>
                    <Button
                        variant="outlined"
                        className={styles.projectButton}
                        style={{ borderColor: "black", color: "black" }}
                        onClick={()=> setOpenShareModal(true)}
                    >
                        Share
                    </Button>
                </div>

            {openShareModal && <ShareModal openShareModal={openShareModal} handleCloseShareModal={handleCloseShareModal} handleCopyLink={handleCopyLink} />}

            </div>
        </div>
    );
};

function StatusCard({ data }) {
    return (
        <div className={styles.dashboardCard} style={{
            display: "flex",
            background: "white",
            padding: "20px",
            borderRadius: "8px",
            border: "1px solid #B3B3B3",
            width: "100%",
            flexDirection: "row",
            gap: "15px",
        }}>
            <RiCoinsLine style={{ color: "#00A6A6", fontSize: "30px", alignSelf: "center" }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {data.isMoney && "$"}{data.value}
                </Typography>
                <Typography style={{ color: "#979797", fontSize: "14px" }}>
                    {data.title}
                </Typography>
            </div>
        </div>
    );
}

export default DashboardPage;
