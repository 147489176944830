import React, { useState } from 'react';
import {  Typography, Button, Dialog } from '@mui/material';


const SuccessModal = ({ open, onClose }) => (
    <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
            style: {
                borderRadius: '12px',
                padding: '20px',
                textAlign: 'center'
            }
        }}
    >
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center", padding: "10px 20px" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>Successful!</Typography>
            <Typography style={{ fontSize: "16px" }}>Waa lagu guuleystay bixinta deeqda.</Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                style={{
                    backgroundColor: '#00A6A6',
                    fontWeight: 'bold',
                    marginTop: '20px',
                    width: "180px",
                    height: '40px',
                    fontSize: '16px'
                }}
            >
                OK
            </Button>
        </div>
    </Dialog>
);

export default SuccessModal
