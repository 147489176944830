import {
    Typography,
    Button,
    Box,
    Modal,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
  } from "@mui/material";
  import { MdClose } from "react-icons/md";
  import styles from "../../Pages/ProjectDetails.module.css";
  import { useEffect, useState } from "react";
  import axios from "axios";
  import { constants } from "../../Helpers/constantsFile";
  import moment from "moment";
  
  export default function DonationsModal({
    openDonationsModal,
    handleCloseDonationsModal,
    handleDonateNowClick,
    project,
  }) {
    const [donors, setDonors] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const getRelativeTime = (date) => {
      const now = moment();
      const createdAt = moment(date);
  
      // Check if the donation is from today or within the last 24 hours
      const diffInMinutes = now.diff(createdAt, "minutes");
      if (diffInMinutes === 0) {
        return `now`; // If the difference is 0 minutes, display "now"
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
      }
  
      const diffInHours = now.diff(createdAt, "hours");
      if (diffInHours < 24) {
        return `${diffInHours} hours ago`;
      }
  
      // If not within the last 24 hours, show days ago
      const diffInDays = now.diff(createdAt, "days");
      return `${diffInDays} days ago`;
    };
  
    useEffect(() => {
      const fetchDonations = () => {
          setLoading(true);
          axios
              .get(`${constants.baseUrl}/projects/get-project-donations/${project?.id}`)
              .then((res) => {
                  const sortedDonors = res?.data?.data?.donors?.sort((a, b) => 
                      new Date(b.created_at) - new Date(a.created_at) // Sort descending by created_at
                  );
                  setDonors(sortedDonors); // Set the sorted donors
                  setLoading(false);
              })
              .catch((err) => {
                  alert(err.response?.data?.message);
                  setLoading(false);
              });
      };
  
      fetchDonations();
  }, []);
  
  
    return (
      <Modal open={openDonationsModal} onClose={handleCloseDonationsModal}>
        <Box
          className={`${styles.modalContainer} ${styles.responsiveModal}`}
          style={{ borderRadius: "12px", padding: "10px" }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
            <Typography variant="h6" fontWeight="bold">
              Donations ({project.totalDonors})
            </Typography>
            <MdClose
              onClick={handleCloseDonationsModal}
              style={{ fontSize: "24px", color: "black", cursor: "pointer" }}
            />
          </Box>
          {loading && (
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
              <p>Loading donors...</p>
            </Box>
          )}
          {!loading && (
            <Box className={styles.scrollableList}>
              {donors.length === 0 ? (
                <Typography variant="body1" textAlign="center" color="textSecondary">
                  There are no donations.
                </Typography>
              ) : (
                <List>
                  {donors.map((donor, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <span role="img" aria-label="donor">
                            👤
                          </span>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={donor?.donor ? donor?.donor : "No name"}
                        secondary={
                          <>
                            <Typography component="span" fontWeight="bold">
                              ${donor?.donation?.toFixed(0)}
                            </Typography>{" "}
                            - {getRelativeTime(donor?.created_at)}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          )}
          {!loading && (
            <Box padding={2} style={{ paddingBottom: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  backgroundColor: "#00A6A6",
                  fontWeight: "bold",
                  fontSize: "16px",
                  height: "50px",
                }}
                onClick={handleDonateNowClick}
              >
                DONATE NOW
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    );
  }
  