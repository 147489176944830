import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Typography, Button, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { constants } from '../Helpers/constantsFile';
import styles from './ProjectDetails.module.css';
import ShareModal from '../containers/modals/ShareModal';
import ContactModal from '../containers/modals/ContactModal';
import DonationsModal from '../containers/modals/DonationsModal';
import Organization from '../containers/projectDetails/Organization';
import { Helmet } from 'react-helmet';

const ProjectDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const projects = useSelector((state) => state.projects.projects);

    // Find the project based on the `id` from Redux
    const projectFromState = projects.find((proj) => proj.id === id);

    const [project, setProject] = useState(projectFromState || null);
    const [loading, setLoading] = useState(!projectFromState);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [openDonationsModal, setOpenDonationsModal] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [openContactModal, setOpenContactModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);

    // Fetch project from backend if not found in state
    useEffect(() => {
        if (!projectFromState) {
            const fetchProject = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(`${constants.baseUrl}/projects/${id}`);
                    setProject(response?.data?.data?.project);
                    console.log(response?.data?.data?.project)
                } catch (err) {
                    setError('Failed to fetch project. Please try again.');
                } finally {
                    setLoading(false);
                }
            };

            fetchProject();
        }
    }, [id, projectFromState]);

    // Update progress bar
    useEffect(() => {
        if (project) {
            const targetProgress = Math.min(
                (project.totalDonations / project.targetDonations) * 100,
                100
            );
            setProgress(0);
            const timer = setInterval(() => {
                setProgress((prev) => {
                    if (prev < targetProgress) {
                        return Math.min(prev + 1, targetProgress);
                    } else {
                        clearInterval(timer);
                        return prev;
                    }
                });
            }, 10);

            return () => clearInterval(timer);
        }
    }, [project]);

    const handleOpenDonationsModal = () => setOpenDonationsModal(true);
    const handleCloseDonationsModal = () => setOpenDonationsModal(false);
    const handleOpenContactModal = () => setOpenContactModal(true);
    const handleCloseContactModal = () => setOpenContactModal(false);
    const handleOpenShareModal = () => setOpenShareModal(true);
    const handleCloseShareModal = () => setOpenShareModal(false);

    const toggleDescription = () => setShowFullDescription(!showFullDescription);

    const handleDonateNowClick = () => {
        navigate('/donate', { state: { project } });
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
    };

    if (loading) {
        return (
            <Container style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress />
            </Container>
        );
    }
    
    if (error) {
        return <Typography variant="h6" color="error">{error}</Typography>;
    }

    if (!project) {
        return <Typography variant="h6">Project not found</Typography>;
    }

    return (
        <Container className={styles.projectDetails}>
               <Helmet>
                <title>{project.title}</title>
                <meta name="description" content={project.description?.substring(0, 150)} />
                <meta property="og:title" content={project.title} />
                <meta property="og:description" content={project.description?.substring(0, 150)} />
                <meta property="og:image" content={project.previewImage} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <img src={project.previewImage} alt={project.title} className={styles.projectImage} />
            <Typography className={styles.projectTitle} align="left" style={{ fontWeight: 'bold', fontSize: '20px' }} gutterBottom>
                {project.title}
            </Typography>

            <div className={styles.sidebar}>
                <Box className={styles.progressSection}>
                    <Box>
                        <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                            ${project.totalDonations?.toFixed(0)} la aruuriyay
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={{ fontSize: '15px' }}>
                         ${project.targetDonations?.toFixed(0)} hadafka - {project.totalDonors?.toFixed(0)} deeqood
                        </Typography>
                    </Box>

                    <Box position="relative" display="inline-flex">
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size={70}
                            thickness={5}
                            sx={{ color: '#E6E6E6' }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={progress}
                            size={70}
                            thickness={5}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                color: '#00A651',
                                '& .MuiCircularProgress-circle': {
                                    strokeLinecap: 'round',
                                },
                            }}
                        />
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="caption" component="div" color="textSecondary">
                                {`${Math.round(progress)}%`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        style={{
                            height: '50px',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#00A6A6',
                            border: '1px solid #00A6A6',
                            marginTop: '8px'
                        }}
                        onClick={handleOpenShareModal}
                    >
                        SHARE
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: '#00A6A6', fontWeight: 'bold', fontSize: '16px', height: '50px' }}
                        onClick={handleDonateNowClick}
                    >
                        DONATE NOW
                    </Button>
                    <Typography
                        variant="body2"
                        color="primary"
                        className={styles.donatersLink}
                        align="left"
                        style={{
                            color: 'black',
                            textDecoration: 'underline',
                            fontSize: '15px',
                            marginRight: 'auto',
                            cursor: 'pointer'
                        }}
                        onClick={handleOpenDonationsModal}
                    >
                        Arag liiska deeq bixiyaasha
                    </Typography>
                </Box>
            </div>

            <Box marginTop={3} align="left">
                <Typography variant="body1" className={styles.projectDescription}>
                    {showFullDescription ? project?.description : `${project?.description?.substring(0, 270)}...`}
                </Typography>
                <Typography variant="body2" color="primary" style={{ cursor: 'pointer', marginTop: '8px' }} onClick={toggleDescription}>
                    {showFullDescription ? "Show Less" : "Read More"}
                </Typography>
            </Box>
                    
            <Organization handleOpenContactModal={handleOpenContactModal} organizer={project?.organizer} />

            {openShareModal && 
            <ShareModal
            openShareModal={openShareModal}
            handleCloseShareModal={handleCloseShareModal}
            handleCopyLink={handleCopyLink}
            shareUrl={window.location.href} // Pass the current page's URL
        />}

            {openDonationsModal && <DonationsModal openDonationsModal={openDonationsModal} handleCloseDonationsModal={handleCloseDonationsModal}
                handleDonateNowClick={handleDonateNowClick} project={project} />}

            {openContactModal && <ContactModal openContactModal={openContactModal} organizer={project?.organizer}
                handleCloseContactModal={handleCloseContactModal} />}
        </Container>
    );
};

export default ProjectDetails;
