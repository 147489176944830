import React, { useState, useEffect } from 'react';
import { Container, Typography, LinearProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import useFetch from '../funcrions/DataFetchers';
import { useSelector } from 'react-redux';

const HomePage = ({ isSearching }) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const projects = useSelector(state => state.projects.projects)

    const { isLoading } = useFetch('projects', 'projects');

    useEffect(() => {
        if (projects) {
            setFilteredProjects(projects); // Initialize filteredProjects with fetched data
        }
    }, [projects]);

    const projectClickHandler = (project) => {
        navigate(`/project/${project.id}`, { state: { project } });
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query) {
            const filtered = projects?.filter((project) =>
                project.title.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredProjects(filtered || []);
        } else {
            setFilteredProjects(projects || []);
        }
    };

    return (
        <div style={{ background: 'white', width: '100%' }}>
            {/* Conditional Hero Section */}
            {!isSearching && (
                <div className="hero-section">
                    <div className="hero-overlay">
                        <Container>
                            <Typography variant="subtitle1">Leading Somali Crowdfunding</Typography>
                            <Typography variant="h4" >Hoyga samafalka iyo samafalaha soomaaliya</Typography>
                        </Container>
                    </div>
                </div>
            )}

            {/* Search Projects Section */}
            {isSearching && (
                <Container style={{ marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Search Projects
                    </Typography>
                    <input
                        type="text"
                        placeholder="Search for projects..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                        style={{
                            width: '100%',
                            padding: '10px',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            marginBottom: '20px',
                        }}
                    />
                </Container>
            )}

            <Container>
                {!isSearching && (
                    <h2 className="project-title" style={{ marginTop: '25px' }}>
                        Hel mashruucyo mudan in la taageero
                    </h2>
                )}

                <div className="project-grid">
                    {isLoading
                        ? Array.from({ length: 6 }).map((_, index) => (
                            <div key={index} className="skeleton-card">
                                <div className="skeleton-image"></div>
                                <div className="skeleton-content">
                                    <div className="skeleton-line skeleton-text"></div>
                                    <div className="skeleton-line skeleton-bar"></div>
                                    <div className="skeleton-line skeleton-text"></div>
                                </div>
                            </div>
                        ))
                        : filteredProjects.map((project) => (
                            <Project key={project.id} project={project} onProjectClick={projectClickHandler} />
                        ))}
                    {!isLoading && filteredProjects.length === 0 && (
                        <Typography variant="body1" style={{ marginTop: '20px' }}>
                            No projects found.
                        </Typography>
                    )}
                </div>
            </Container>
        </div>
    );
};

const Project = ({ project, onProjectClick }) => {
    const calculateProgress = (raised, goal) => {
        if (raised > goal) return 100;
        return (raised / goal) * 100;
    };

    return (
        <div className="project-card" onClick={() => onProjectClick(project)}>
            <img src={project.previewImage} alt={project.title} className="project-image" />
            <div className="project-content">
                <p className="donations-text">{project.totalDonors} deeq</p>
                <p className="project-name">{project.title}</p>
                <LinearProgress
                    style={{ marginTop: '10px' }}
                    sx={{
                        height: '10px',
                        backgroundColor: '#E6E6E6',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#00A6A6',
                        },
                    }}
                    variant="determinate"
                    value={calculateProgress(project.totalDonations, project.targetDonations)}
                    className="progress-bar"
                />
                <p className="raised-amount">${project.totalDonations.toFixed(2)} la aruuriyay</p>
            </div>
        </div>
    );
};

export default HomePage;
